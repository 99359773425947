<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row>
    <v-col md="8" offset-md="2">
      <card page-title="Filter Mailbox" page-icon="mdi-filter">
        <v-form v-model="valid" ref="mailboxForm">
          <v-file-input
              accept=".csv"
              label="Select a CSV File"
              small-chips
              outlined
              :rules="requiredFieldRule"
              @change.native="csvHandler"
              @click:clear="onClearCsvHandler"
          />
        </v-form>

        <v-card-actions>
          <v-spacer/>
          <save-button text="Import" @onButtonClick="onImportHandler()"/>
        </v-card-actions>
      </card>
    </v-col>
  </v-row>
</template>
<script>
import Card from "../components/Card";
import SaveButton from "../components/buttons/SaveButton";
import MailBox from "../services/MailBox";
import Validation from "../components/mixins/Validation";

export default {
  name: 'FilterMailbox',
  components: {SaveButton, Card},
  mixins: [Validation],
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Filter Mailbox | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  },

  data() {
    return {
      valid: true,
      currentItem: {
        mailCsv: null
      }
    }
  },
  methods: {
    csvHandler(event) {
      this.currentItem.mailCsv = event.target.files[0];
    },
    onClearCsvHandler() {
      this.currentItem.mailCsv = '';
    },
    async onImportHandler() {
      try {
        if (!this.$refs.mailboxForm.validate()) return;
        const {data} = await MailBox.emailFilter(this.currentItem);
        this.$store.commit("SET_SNACKBAR", {text: data.message, type: "info"});
        this.$refs.mailboxForm.reset()
      } catch (error) {
        this.$store.commit("SET_SNACKBAR", {text: error.response.data.message, type: "error"});
      }
    }
  }
}
</script>